<template>
  <v-container fluid class="custom-container-details">
    <div>
      <v-row wrap no-gutters >
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="tab"
            class="custom-tab-design"
            background-color="transparent"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-translations"
              class="ml-2"
            >
              {{ $lang.labels.translations }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.exampleVariables }}
            </v-tab>
            <v-tab
              :key="4"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" custom-bg">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-1 custom-bg">
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-3"
                >
                  <v-row wrap no-gutters justify="space-between" class="pb-5">
                    <v-col
                      cols="8"
                      class="d-flex"
                    >
                      <v-text-field
                        v-model="template.name"
                        outlined
                        dense
                        :label="$lang.labels.name"
                        required
                        :rules="[
                          v => !!v.trim() || $lang.errors.required,
                          v => (v.trim().length > 1 && v.trim().length <= 250) || $lang.errors.nameTooShortOrLong
                        ]"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit || template.isSystem"
                        data-cy="template-name"
                        class="required-asterisk copy-name-icon mr-5"
                        append-icon="mdi-vector-combine"
                        @click:append="copyField()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pl-2">
                      <v-select
                        v-model="template.engine"
                        :items="enginesFormatted"
                        :label="$lang.labels.engine"
                        required
                        :rules="[v => !!v || $lang.labels.required]"
                        outlined
                        hide-details
                        dense
                        class="required-asterisk"
                        data-cy="template-engine"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters class="pb-4">
                    <v-col
                      cols="12"
                      style="position: relative"
                    >
                      <div class="d-flex justify-end">
                        <v-row no-gutters class="pt-1 pb-5 mb-2 align-center" style="max-width: 100%">
                          <v-col cols="12" md="1">
                            <h3>{{ $lang.labels.body }}</h3>
                          </v-col>
                          <v-col cols="12" md="11" class="justify-end mt-2 mt-md-0">
                            <v-row no-gutters class="justify-end align-center">
                              <v-col cols="6" md="4">
                                <v-select
                                  :disabled="!template.engine"
                                  :items="variables"
                                  :label="$lang.labels.addVariable"
                                  outlined
                                  hide-details
                                  dense
                                  data-cy="variables"
                                  item-text="key"
                                  item-value="key"
                                  style="min-width: 150px;"
                                  @change="addShortCode"
                                ></v-select>
                              </v-col>
                              <v-col cols="6" md="4">
                                <v-autocomplete
                                  :items="translationsSearchList"
                                  :loading="isLoadingTranslations"
                                  :search-input.sync="searchTranslations"
                                  :label="$lang.labels.addTranslation"
                                  outlined
                                  hide-details
                                  dense
                                  data-cy="translations"
                                  class="ml-2"
                                  style="min-width: 150px;"
                                  @change="addTranslation"
                                >
                                  <template v-slot:no-data>
                                    <div v-if="searchTranslations?.length > 1 && translationsSearchList.length === 0 && !isLoadingTranslations">
                                      No data available
                                    </div>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="4" class="justify-end text-right d-flex pl-md-2 mt-2 mt-md-0">
                                <header-search
                                  :search-field="'getTemplates'"
                                  :should-hide-on-small-screens="false"
                                  @addTemplate="addTemplateToBody"
                                ></header-search>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row no-gutters class="position-relative">
                        <prism-editor
                          v-model="template.body"
                          class="my-editor"
                          :highlight="highlighter"
                          line-numbers
                          data-cy="template-body"
                          @blur="handleBlur"
                          @focus="cursorPosition = ''"
                        ></prism-editor>
                        <v-btn
                          style="position: absolute; top: 4px; right: 4px"
                          :disabled="!template.engine"
                          color="info"
                          class="ml-2"
                          icon
                          small
                          data-cy="template-help"
                          @click="templateHelpShow = true"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class=" pb-2">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          outlined
                          color="primary"

                          data-cy="template-back"
                          :to="{ name: 'templates', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="template.name"
                          :title="$lang.actions.delete"
                          type="template"
                          :is-disabled="!userCanDelete || template.isSystem"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'template-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteTemplateFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'templates' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-flex justify-end">
                        <div class="mr-5">
                          <v-btn
                            icon
                            color="primary ms-1"
                            class="mr-1"
                            style="margin-top: 2px"
                            title="copy"
                            :disabled="!copyPaste"
                            @click="copyFields()"
                          >
                            <v-icon>mdi-vector-combine</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="primary"
                            style="margin-top: 2px"
                            title="paste"
                            :disabled="!copyPaste"
                            @click="pasteFields()"
                          >
                            <v-icon>mdi-clipboard</v-icon>
                          </v-btn>
                        </div>
                        <div>
                          <v-btn
                            outlined
                            :disabled="!valid || lock || !userCanEdit"
                            color="primary"
                            class="button-default-width color-black"
                            data-cy="template-generate-html"
                            @click="generateFile('html')"
                          >
                            <v-icon left dark class="mr-1">mdi mdi-package-up</v-icon>
                            {{ $lang.actions.HTML }}
                          </v-btn>

                          <v-btn
                            outlined
                            :disabled="!valid || lock || !userCanEdit"
                            color="primary"
                            class="ml-2 button-default-width color-black"
                            data-cy="template-generate-pdf"
                            @click="generateFile('pdf')"
                          >
                            <v-icon left dark class="mr-1">mdi mdi-package-up</v-icon>
                            {{ $lang.actions.pdf }}
                          </v-btn>
                          <v-btn
                            outlined
                            :disabled="!valid || lock || !userCanEdit || !isEdit"
                            color="primary"
                            class="ml-2 color-accent-text"
                            @click="copyCreateTemplate(template)"
                          >
                            <v-icon
                              left
                              dark
                            >
                              mdi-content-copy
                            </v-icon>
                            {{ $lang.actions.copyCreate }}
                          </v-btn>
                          <v-btn
                            :disabled="!isFormValid"
                            color="primary"
                            class="button-default-width ml-2"
                            data-cy="template-submit"
                            @click="submit()"
                          >
                            <v-icon
                              left
                              dark
                              class="mr-1"
                            >
                              mdi mdi-floppy
                            </v-icon>
                            {{ $lang.actions.submit }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row no-gutters wrap class="pt-4">
                  <div class="d-inline-flex">
                    <div class="d-flex justify-content-center align-center align-self-center">
                      <h3>{{ $lang.routes.translations }}</h3>

                      <v-btn
                        outlined
                        color="accent"
                        class="ml-2"
                        :disabled="!isEdit"
                        data-cy="template-translation-add"
                        @click="translations.push({ language: '', name: '', text: '', isEdit: false })"
                      >
                        <v-icon small >mdi-plus</v-icon>
                        <span color="accent" class=" pl-1">{{ $lang.actions.addField }}</span>
                      </v-btn>
                    </div>
                  </div>
                  <v-col
                    cols="12"
                    class="pt-3"
                  >
                    <template v-for="(trans, i) in translations">
                      <v-row :key="i" no-gutters wrap class="pt-2">
                        <v-col
                          cols="2"
                          class="pr-1"
                        >
                          <v-select
                            v-model="trans.language"
                            :items="availableLanguages"
                            :label="$lang.labels.language"
                            outlined
                            dense
                            required
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-language-${i}`"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="7"
                        >
                          <v-text-field
                            v-model="trans.name"
                            outlined
                            dense
                            :label="$lang.labels.name"
                            required
                            :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, false) || $lang.errors.variableJavaWrong]"
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-name-${i}`"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          class="pl-1 text-right"
                        >
                          <div class="d-inline-flex">
                            <v-btn
                              outlined
                              color="primary"
                              :disabled="!trans.language || !trans.name || !trans.text"
                              :data-cy="`template-translation-save-${i}`"
                              @click="submitTranslation(trans, i)"
                            >
                              <v-icon
                                left
                                dark
                                class="mr-1"
                              >
                                mdi mdi-content-save-all
                              </v-icon>
                              {{ trans.isEdit ? $lang.actions.update : $lang.actions.save }}
                            </v-btn>
                            <v-btn
                              outlined
                              color="error"
                              class="ml-2"
                              :data-cy="`template-translation-delete-${i}`"
                              @click="confirmDeletion(trans, i)"
                            >
                              <v-icon
                                left
                                dark
                                class="mr-1"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                              {{ $lang.actions.delete }}
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="trans.text"
                            outlined
                            dense
                            rows="4"
                            auto-grow
                            :label="$lang.labels.body"
                            required
                            :rules="[v => !!v || $lang.labels.required]"
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-text-${i}`"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>

            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="pa-5 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-2">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-2">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-2">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
              eager
            >
              <v-card class="pa-2 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col v-if="!loading" cols="12">
                      <key-val :data="template.exampleVariables" :can-edit="userCanEdit" @dataChanged="template.exampleVariables = $event" @formValid="formValidLocal = $event" />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="4"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreTemplate($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
        <yes-no-modal :title="$lang.actions.delete" :action-text="$lang.header.sureDeleteTranslation" @submit="deleteTranslation" @closeDialog="tempItem = null; tempIndex = null; yesNoShow = false"></yes-no-modal>
      </v-dialog>
      <v-dialog v-if="templateHelpShow" v-model="templateHelpShow" max-width="30%">
        <template-help-modal :engine="template.engine" @closeDialog="templateHelpShow = false"></template-help-modal>
      </v-dialog>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
      <multi-errors-snackbar
        :show="showSnackErrors"
        :errors="errorsForSnackbar"
        @close="showSnackErrors = false; errorsForSnackbar = []"
      />
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import {
  createHtmlUsingPOST as createHtml,
  createPdfUsingPOST as createPdf,
  createTextTemplateTranslationUsingPOST as createTranslation,
  createTextTemplateUsingPOST as createTemplate,
  deleteTextTemplateTranslationUsingDELETE as deleteTranslation,
  getHistoriesUsingGET as getHistories,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplateTranslationsUsingGET as getTranslations,
  updateTextTemplateTranslationUsingPUT as updateTranslation,
  updateTextTemplateUsingPUT as updateTemplate,
  deleteTextTemplateUsingDELETE as deleteTemplate,
  getTranslationsUsingGET as getGlobalTranslations
} from '@/utils/api'
import YesNoModal from '@/components/ui/modals/YesNoModal'
import TemplateHelpModal from '@/components/ui/modals/TemplateHelpModal'
import KeyVal from './KeyValPairModal'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import HeaderSearch from '@/components/header/HeaderSearch'

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import copy from 'copy-to-clipboard'
import { mapActions } from 'vuex'
import { castValue, copyCreateTemplate, getRolesWithoutAuth } from '@/utils/helpers'
import { bus } from '@/main'

export default {
  components: {
    YesNoModal,
    TemplateHelpModal,
    Table,
    PrismEditor,
    KeyVal,
    UserRolesSelect,
    HeaderSearch,
    MultiErrorsSnackbar,
    ActionButtonWithConfirmation
  },
  data() {
    return {
      formValidLocal: false,
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      copyPaste: false,
      tab: 0,
      yesNoShow: false,
      templateHelpShow: false,
      err: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      template: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        status: '',
        body: '',
        roles: [],
        isSystem: false,
        engine: '',
        exampleVariables: null
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      translations: [],
      tempItem: null,
      tempIndex: null,
      history: { items: [], meta: {} },
      isSuperUser: false,
      engines: ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY', 'MARKDOWN'].sort(),
      cursorPosition: '',
      variables: [],
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      searchTranslations: '',
      translationsSearchList: [],
      isLoadingTranslations: false
    }
  },
  computed: {
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    },
    enginesFormatted() {
      return this.engines.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.template.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.template.modifiedOn)}, ID: ${this.template.id}`
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit && this.template?.body.trim() && this.formValidLocal
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    },
    'template.engine': {
      handler(newVal, oldVal) {
        if (['HANDLEBARS', 'MUSTACHE', 'MARKDOWN'].includes(oldVal) && !['HANDLEBARS', 'MUSTACHE', 'MARKDOWN'].includes(newVal)) {
          const rg = /[\\{{]{2}([a-zA-Z0-9]{1,})[\\}}]{2}/gm

          this.replaceShortcodes(rg, 2, 2)
        } else if (oldVal === 'THYMELEAF') {
          const rg = /[\\$]{1}[\\{]{1}([a-zA-Z0-9]{1,})[\\}]{1}/gm

          this.replaceShortcodes(rg, 2, 1)
        } else if (oldVal === 'VELOCITY') {
          const rg = /[\\$]{1}([a-zA-Z0-9]{1,})/gm

          this.replaceShortcodes(rg, 1, 0)
        }
      }
    },
    'template.exampleVariables': {
      handler() {
        this.generateVariableOptions()
      }
    },
    searchTranslations() {
      if (this.searchTranslations && this.searchTranslations.length > 1) {
        this.searchGlobalTranslations()
      } else {
        this.translationsSearchList = []

        return
      }
    },
    tab() {
      if (this.tab === 4) {
        this.fetchHistory()
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'templateEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TEMPLATE_DELETER') || this.isSuperUser
    }

    if ('showCopyPaste' in localStorage) {
      this.copyPaste = JSON.parse(localStorage.showCopyPaste)
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getTemplate({ templateId: this.$route.params.id })
            .then((res) => {
              this.template = res.data.data

              this.generateVariableOptions()

              if (this.template.roles) {
                this.editRolesIds = this.template.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.template.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.template.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
              } else {
                this.userCanEdit = true
              }

              for (const row in this.template.exampleVariables) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.template.exampleVariables.hasOwnProperty(row)) {
                  this.template.exampleVariables[row] = this.template.exampleVariables[row] ? typeof this.template.exampleVariables[row] === 'string' ? '"' + this.template.exampleVariables[row] + '"' : JSON.stringify(this.template.exampleVariables[row]) : ''
                }
              }

              this.loading = false

              getTranslations({ templateId: this.$route.params.id })
                .then((res) => {
                  this.translations = res.data.data.items.map((x) => {
                    x.isEdit = true

                    return x
                  })
                })
                .catch((err) => {
                  this.addSnackbar({
                    message: err,
                    timeout: 5000,
                    color: 'error'
                  })
                })

            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          const tempCopy = JSON.parse(JSON.stringify(this.$route.params.restore))

          tempCopy.id = 0

          this.template = tempCopy
          this.generateVariableOptions()
          this.loading = false
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            const tempCopy = JSON.parse(this.$route.params.copy)

            this.template = tempCopy.tempItem
            this.translations = tempCopy.tempTranslations
            this.generateVariableOptions()
          }
          this.loading = false
        }
      })
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    copyCreateTemplate,
    searchGlobalTranslations() {
      this.isLoadingTranslations = true

      const obj = {}

      obj.page = 1
      obj.size = 1000

      if (this.searchTranslations && this.searchTranslations.length > 1) {
        obj.name = this.searchTranslations
      }

      getGlobalTranslations(obj)
        .then((res) => {
          const localTranslations = this.translations && this.translations.length > 0 ? this.translations.map((x) => `${x.name} (Local)`) : []

          this.translationsSearchList = [...localTranslations, ...res.data.data.items.map((x) => `${x.name} (Global)`)].map((value) => value.replace(/^\$/, ''))
          this.isLoadingTranslations = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
          this.isLoadingTranslations = false
        })
    },
    copyField() {
      if (this.template.name) {
        copy(this.template.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    deleteTemplateFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteTemplate({ templateId: this.$route.params.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    addTemplateToBody(val) {
      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.template.body.length

      this.template.body = this.template.body.slice(0, position) + '<flowy-template>' + val + '</flowy-template>' + this.template.body.slice(position)
    },
    copyFields() {
      localStorage.setItem('copiedTemplateData', this.template.body)
      localStorage.setItem('copiedTemplateTranslation', JSON.stringify(this.translations))
    },
    pasteFields() {
      this.template.body = localStorage.getItem('copiedTemplateData')
      this.translations = JSON.parse(localStorage.getItem('copiedTemplateTranslation'))
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    fetchHistory(options) {
      if (!this.template.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.template.id
      obj.resourceType = 'TEXT_TEMPLATE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    restoreTemplate(data) {
      this.template = data

      if (this.template && this.template.roles) {
        this.editRolesIds = this.template.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.template.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.template.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

        for (const row in this.template.exampleVariables) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.template.exampleVariables.hasOwnProperty(row)) {
            this.template.exampleVariables[row] = this.template.exampleVariables[row] ? typeof this.template.exampleVariables[row] === 'string' ? '"' + this.template.exampleVariables[row] + '"' : JSON.stringify(this.template.exampleVariables[row]) : ''
          }
        }
      }

      getTranslations({ templateId: this.$route.params.id })
        .then((res) => {
          this.translations = res.data.data.items.map((x) => {
            x.isEdit = true

            return x
          })
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    confirmDeletion(item, index) {
      this.tempIndex = index
      this.tempItem = item
      this.yesNoShow = true
    },
    async deleteTranslation() {

      if (!this.tempItem.isEdit) {
        this.translations.splice(this.tempIndex, 1)
        this.tempIndex = null
        this.tempItem = null
        this.yesNoShow = false

        return
      }

      this.lock = true
      this.yesNoShow = false

      const { id } = this.template

      let res = null

      try {

        res = await deleteTranslation({ templateId: id, translationId: this.tempItem.id })

        if (res && res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.translationDelete,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
          this.tempIndex = null
          this.tempItem = null

          return
        }
        this.addSnackbar({
          message: this.$lang.success.translationDeleted,
          timeout: 5000,
          color: 'success'
        })

        this.lock = false

        this.translations.splice(this.tempIndex, 1)

        this.tempIndex = null
        this.tempItem = null

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.tempIndex = null
        this.tempItem = null
      }
    },
    async submitTranslation(item, index) {
      this.lock = true

      let res = null

      const { id } = this.template

      const payload = {
        language: item.language,
        name: item.name,
        text: item.text
      }

      try {

        res = item.isEdit ? await updateTranslation({ templateId: id, translationId: item.id, body: payload }) : await createTranslation({ templateId: id, body: payload })

        if (res && res.status !== 200) {
          const errorMessage = item.isEdit ? this.$lang.errors.translationUpdate : this.$lang.errors.translationCreate

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = item.isEdit ? this.$lang.success.translationUpdated : this.$lang.success.translationCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false

        item.isEdit = true

        this.translations[index] = { ...res.data.data, isEdit: true }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    openFileInNewTab(content, fileType) {
      const file = new Blob([content], { type: fileType })
      const fileURL = URL.createObjectURL(file)

      // Open the blob URL in a new tab
      const newTab = window.open()

      // Make sure the new tab is opened before writing content
      if (newTab) {
        newTab.location.href = fileURL
      } else {
        console.error('Failed to open a new tab. Please check if popups are blocked.')
      }
    },
    downloadFile(content, fileName, fileType) {
      const file = new Blob([content], { type: fileType })
      const reader = new FileReader()

      reader.onload = function () {
        const popup = window.open()
        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }

      reader.readAsDataURL(file)
    },
    async generateFile(type) {
      this.loading = true

      const { engine, body, exampleVariables } = this.template
      let res = null

      for (const row in exampleVariables) {
        // eslint-disable-next-line no-prototype-builtins
        if (exampleVariables.hasOwnProperty(row)) {
          exampleVariables[row] = castValue(exampleVariables[row])
        }
      }

      try {
        // Adjust API call based on file type
        const apiMethod = type === 'pdf' ? createPdf : createHtml
        const fileType = type === 'pdf' ? 'application/pdf' : 'application/html'
        const fileExtension = type === 'pdf' ? '.pdf' : '.html'

        res = await apiMethod({
          body: { templateBody: body, type: engine, variables: exampleVariables },
          $config: { responseType: 'blob' }
        })

        if (res && res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors[`${type}Generate`],
            timeout: 5000,
            color: 'error'
          })
          this.loading = false

          return
        }

        this.loading = false
        if (type !== 'pdf') {
          this.openFileInNewTab(res.data, 'text/html')
        } else {
          this.downloadFile(res.data, `${this.template.name}${fileExtension}`, fileType)
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
      }
    },
    copyCreate(item) {
      copyCreateTemplate(item)
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    async submit() {

      const localTemplate = JSON.parse(JSON.stringify(this.template))

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      let res = null

      const { id } = localTemplate

      if (!this.isEdit) {
        delete localTemplate.id
      }

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      localTemplate.roles = tempRoles

      for (const row in localTemplate.exampleVariables) {
        // eslint-disable-next-line no-prototype-builtins
        if (localTemplate.exampleVariables.hasOwnProperty(row)) {
          localTemplate.exampleVariables[row] = castValue(localTemplate.exampleVariables[row])
        }
      }

      try {

        res = this.isEdit ? await updateTemplate({ templateId: id, body: localTemplate }) : await createTemplate({ body: localTemplate })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.templateUpdate : this.$lang.errors.templateCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.templateUpdated : this.$lang.success.templateCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        if (!this.isEdit) {
          this.$router.push({
            name: 'templateEdit',
            params: {
              id: res.data.data.id,
              message: this.success
            }
          })
        }

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    addTranslation(event) {
      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.template.body.length
      let value = ''

      if (['HANDLEBARS', 'MUSTACHE'].includes(this.template.engine)) {
        value = '{{' + event + '}}'
      } else if (this.template.engine === 'THYMELEAF') {
        value = '${' + event + '}'
      } else if (this.template.engine === 'VELOCITY') {
        value = '$' + event
      } else if (this.template.engine === 'MARKDOWN') {
        value = '{{' + event + '}}'
      }

      this.template.body = this.template.body.slice(0, position) + value.replace(/\s?\(Global\)\s?/g, '').replace(/\s?\(Local\)\s?/g, '').trim() + this.template.body.slice(position)
    },
    addShortCode(event) {
      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.template.body.length
      let value = ''

      if (['HANDLEBARS', 'MUSTACHE'].includes(this.template.engine)) {
        value = '{{' + event + '}}'
      } else if (this.template.engine === 'THYMELEAF') {
        value = '${' + event + '}'
      } else if (this.template.engine === 'VELOCITY') {
        value = '$' + event
      } else if (this.template.engine === 'MARKDOWN') {
        value = '{{' + event + '}}'
      }

      this.template.body = this.template.body.slice(0, position) + value + this.template.body.slice(position)
    },
    handleBlur(event) {
      this.cursorPosition = event.target.selectionStart
    },
    replaceShortcodes(regexp, start, end) {
      let { body } = this.template

      if (['HANDLEBARS', 'MUSTACHE'].includes(this.template.engine)) {
        const vars = body.match(regexp)

        if (!vars) return

        vars.map((item) => {
          body = body.replaceAll(item, '{{' + item.slice(start, item.length - end) + '}}')

          return item
        })
        this.template.body = body
      } else if (this.template.engine === 'THYMELEAF') {

        const vars = body.match(regexp)

        if (!vars) return

        vars.map((item) => {
          body = body.replaceAll(item, '${' + item.slice(start, item.length - end) + '}')

          return item
        })
        this.template.body = body
      } else if (this.template.engine === 'VELOCITY') {

        const vars = body.match(regexp)

        if (!vars) return

        vars.map((item) => {
          body = body.replaceAll(item, '$' + item.slice(start, item.length - end))

          return item
        })
        this.template.body = body
      } else if (this.template.engine === 'MARKDOWN') {

        const vars = body.match(regexp)

        if (!vars) return

        vars.map((item) => {
          body = body.replaceAll(item, '{{' + item.slice(start, item.length - end) + '}}')

          return item
        })
        this.template.body = body
      }
    },
    generateVariableOptions() {
      if (this.template.exampleVariables) {
        this.variables = Object.entries(this.template.exampleVariables).reduce((o, a) => {
          o.push({
            key: a[0].slice(2),
            value: a[1]
          })

          return o
        }, [])
      } else {
        this.variables = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 55vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
