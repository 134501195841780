<template>
  <v-card class="pa-2">
    <v-card-title class="pb-2">
      <span>{{ $lang.header.templateHelp }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        small
        @click="$emit('closeDialog', true)"
      >
        <v-icon size="22">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row wrap no-gutters class="pb-4">
      <v-col
        cols="12"
      >
        <v-card-text class="pb-2">{{ $lang.header.textTemplateHelp }}</v-card-text>
        <v-textarea
          v-model="textVar[engine]"
          outlined
          dense
          rows="1"
          auto-grow
          :label="$lang.labels.body"
          readonly
        ></v-textarea>
      </v-col>
      <v-col
        v-if="engine === 'THYMELEAF'"
        cols="12"
      >
        <v-card-text class="pb-2">{{ $lang.header.htmlTemplateHelp }}</v-card-text>
        <v-textarea
          v-model="html"
          outlined
          dense
          rows="8"
          auto-grow
          :label="$lang.labels.body"
          readonly
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between">
      <v-btn
        color="primary"
        outlined
        @click="$emit('closeDialog', true)"
      >
        <v-icon
          left
          dark
        >
          mdi-arrow-left
        </v-icon>
        {{ $lang.actions.back }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    engine: {
      type: String,
      default: () => 'THYMELEAF'
    }
  },
  data () {
    return {
      // eslint-disable-next-line
      textVar: {
        HANDLEBARS: '{{var.name}}',
        MUSTACHE: '{{var.test}}',
        // eslint-disable-next-line no-template-curly-in-string
        THYMELEAF: '[(${variableName})]',
        VELOCITY: '$var.name'
      },
      // eslint-disable-next-line
      htmlVar: {
        HANDLEBARS: 'var.name',
        MUSTACHE: 'var.test',
        THYMELEAF: 'variableName',
        VELOCITY: '$var.name'
      },
      html: ''
    }
  },
  created() {
    this.html = '<html>\n' +
      '<head>\n' +
      '</head>\n' +
      '<body>\n' +
      // eslint-disable-next-line
      '<span th:text="${' + this.htmlVar[this.engine] + '}"></span>\n' +
      '</body>\n' +
      '</html>'
  }
}
</script>
<style lang="scss" scoped>
</style>
